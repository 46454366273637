
.path {
    display: flex;
    margin-bottom: 40px;
    color: #a8a8b8;
    .item {
        display: flex;
        justify-content: center;
        align-items: center;
        .text {
            transition: all 0.3s;
            cursor: pointer;
        }
        .text:hover {
            color: #2821fc;
        }
        .modify {
            margin: 0 5px;
            /* margin-top: 1px; */
        }
    }

    .current {
        color: #332d42;
    }
}
.reverse {
    color: #332d42 !important;
    .current {
        color: #a8a8b8 !important;
    }
}
